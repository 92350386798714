;(function ($, window, document, undefined) {
    $(function () {

        // Allow "hover" on all bootstrap dropdown menus, this is
        // mostly useful for the navbar, but will work elsewhere too

        $.fn.unBindBootstrapNavbarCollapse = function() {

            return this.each(function() {

                var collapse = $(this).find('.navbar-collapse');

                var onMouseIn = function() {
                    $(this).addClass('open');
                };

                var onMouseOut = function() {
                    $(this).removeClass('open');
                };

                collapse.find('.dropdown').hover(onMouseIn, onMouseOut);

                collapse.on('hide.bs.collapse', function() {
                    $(this).find('.dropdown').bind('mouseenter', onMouseIn).bind('mouseleave', onMouseOut);
                }).on('show.bs.collapse', function() {
                    $(this).find('.dropdown').unbind('mouseenter').unbind('mouseleave');
                });

            });
        };

        $('.navbar').unBindBootstrapNavbarCollapse();

        $('#vehicle-primary-track').slick({
            dots: false,
            infinite: true,
            speed: 300,
            autoplay: false,
            autoplaySpeed: 5000,
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true,
            nextArrow: '<button type="button" class="slick-next Gallery__next"><span class="glyphicon glyphicon-chevron-right"></span></button>',
            prevArrow: '<button type="button" class="slick-prev Gallery__prev"><span class="glyphicon glyphicon-chevron-left"></span></button>',
            responsive: []
        });

        // --
        
        Object.size = function(o) {
            var size = 0, key;
            for (key in o) {
                if (o.hasOwnProperty(key)) {
                    size++;
                }
            }
            return size;
        };
        
        var objectToSelect = function (e, items) {
  
            var current = e.val();

            e.empty().append(
                $('<option>', { value: '', text: (Object.size(items) > 0 ? '- Please Select -' : '-') })
            );

            $.each(items, function (i, item) {
                e.append($('<option>', {
                    value: i,
                    text: item
                }));
            });

            e.val(current);

        };
        
        $('.Search__form').each(function () {

            var form = $(this),
                dropdowns = form.find('select');

            form.find('select, input[type="checkbox"]').on('change', function () {

                var host = window.location.host
                if (window.location.host == 'localhost') {
                    host = window.location.host + '/' + window.location.pathname.split('/')[1]
                }

                $.get(window.location.protocol + '//' + host + '/wp-json/origin/v1/search-filter', form.serialize(), function (response, status) {
                    if ('success' != status) {
                        console.warn('There was a problem getting data from /wp-json/origin/v1/search-filter');
                        return;
                    }

                    for (var key in response) {
                        objectToSelect(dropdowns.filter('[data-target="'+key+'"]'), response[key]);
                    }

                }, 'json');

            });

        });

    });
})(jQuery, window, document);